import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';
import {Residency} from './residency';
import {isDateBefore, isDateInBetween} from '../config/moment';

export class Resident extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.residentStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      tenantId: toJS(this.tenantId),
      residencies: this.residencies.map((residency) => Residency.createFromPlainObject(residency).asJson()),
    };
  }

  get currentResidency() {
    return this.residencies.find(
      (residency) => (!residency.endDate && isDateBefore(residency.startDate, new Date()))
        || isDateInBetween(residency.startDate, residency.endDate, new Date())
    );
  }

  residencies = [];
}

decorate(Resident, {
  id: observable,
  name: observable,
  residencies: observable,
  tenantId: observable,
});
