/* eslint-disable import/no-cycle */
import {executeGet, executePut} from '../requests';

const resourcesName = 'v1/residents';

export const ResidentApi = {
  getAllNames: () => executeGet(`${resourcesName}/names`),
  getAllCurrent: () => executeGet(`${resourcesName}`),
  getByName: (name) => executeGet(`${resourcesName}/${name}`),
  saveAll: (changedItems) => executePut(`${resourcesName}`, changedItems),
};
