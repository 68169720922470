/* eslint-disable import/no-cycle */
import { maxBy, sortBy } from 'lodash';
import { decorate, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { Profession } from '../../models/profession';
import { Api } from '../../middleware/api';
import { BaseStore } from '../baseStore';

export class ProfessionStore extends BaseStore {
  // Eager loading set to true in order to load the data on start
  constructor(rootStore) {
    super(rootStore, true);
    this.showInactiveInEditor = false;
  }

  getProfessions(onlyActive = true) {
    if (this.isLoading) {
      return [];
    }
    const filteredItems = this.items.filter((profession) => profession.isActive || !onlyActive);
    return sortBy(filteredItems, (item) => item.sorting);
  }

  createNew(translations) {
    const maxValueIdem = maxBy(this.items, (f) => f.sorting);
    const newSort = maxValueIdem !== 'undefined' ? maxValueIdem.sorting + 1 : 0;
    const saveableTranslations = Profession.convertToSavableTranslations(translations);
    super.add(Profession.createFromPlainObject({
      id: uuidv4(),
      sorting: newSort,
      isActive: true,
      translations: saveableTranslations,
    }));
  }

  toggleShowInactiveInEditor() {
    this.showInactiveInEditor = !this.showInactiveInEditor;
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.profession.getAll();
    return serverResponse.data.map((item) => Profession.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.profession.saveAll(changedItems);
  }

  // Deletion not possible, only setting porfessions to inactive
}

decorate(ProfessionStore, {
  /**
   * If the super admin editor should show "deleted" professions
   */
  showInactiveInEditor: observable,
});
