import React from 'react';

const HeaderContent = ({children, style}) => {
  const componentStyle = {display: 'flex', flexGrow: 1, justifyContent: 'space-between'};

  return <div style={{...componentStyle, ...style}}>{children}</div>;
};

const HeaderContentItem = ({hidden, children, style, className}) => {
  const componentStyle = {marginLeft: '1em', marginRight: '1em', display: 'flex', alignItems: 'center'};

  if (!hidden) {
    return (
      <div style={{...componentStyle, ...style}} className={className}>
        {children}
      </div>
    );
  }

  return null;
};

export {HeaderContent, HeaderContentItem};
