import { decorate, observable, toJS } from 'mobx';
import { BaseModel } from './baseModel';

export class Activity extends BaseModel {
  translatedProperties = ['name'];

  constructor(rootStore) {
    super(rootStore, (root) => root.activityStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      parentId: toJS(this.parentId),
      color: toJS(this.color),
      sorting: toJS(this.sorting),
      isActive: toJS(this.isActive),
      translations: toJS(this.translations),
    };
  }
}

decorate(Activity, {
  id: observable,
  parentId: observable,
  color: observable,
  sorting: observable,
  isActive: observable,
  translations: observable,
});
