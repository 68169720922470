import * as React from 'react';
import {Layout, Row, Col, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer, Observer} from 'mobx-react-lite';
import {HeaderContentItem} from './HeaderContent';
import Account from './headerItems/Account';
import {isDarkBackground} from '../../../utils/color';
import somedConfig from '../../../utils/somedConfig';
import {useStore} from '../../../hooks/useStore';

const Left = ({children}) => {
  const {t} = useTranslation();
  const logo = somedConfig.corporateIdentity.image;

  return (
    <div className="left-content">
      {logo && <img src={logo} alt={t('alt.logo')} className="logo" />}
      {children}
    </div>
  );
};

const Right = ({children}) => {
  const {t} = useTranslation();
  const {authStore, timeTrackingStore} = useStore();
  return (
    <div className="right-content">
      {children}
      <HeaderContentItem>
        {authStore.user ? (
          <Row justify="end" style={{alignItems: 'center', gap: '.5em'}}>
            <Observer>
              {() =>
                (timeTrackingStore?.currentActivity ? (
                  <Tooltip title={t('timeTracking.trackingOn')}>
                    {timeTrackingStore.currentRecordedTime?.elapsed}
                  </Tooltip>
                ) : null)}
            </Observer>
            <Account />
          </Row>
        ) : (
          <Col style={{margin: '3em'}} />
        )}
      </HeaderContentItem>
    </div>
  );
};

const Middle = ({children}) => <div className="header-middle">{children}</div>;

const Header = () => {
  const {contextStore} = useStore();

  const backgroundColor = somedConfig.corporateIdentity.navbarColor;
  const color = isDarkBackground(backgroundColor) ? 'white' : 'black';

  return (
    <Layout.Header className="header-content" style={{backgroundColor, color, position: 'fixed', width: '100vw'}}>
      <Left>{contextStore.current?.navBarContentLeft}</Left>
      <Middle>{contextStore.current?.navBarContent}</Middle>
      <Right>{contextStore.current?.navBarContentRight}</Right>
    </Layout.Header>
  );
};

export default observer(Header);
