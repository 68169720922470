/* eslint-disable import/no-cycle */
import {rootStoreInstance} from '../stores/StoreContext';

export class LicenseTypes {
  static None = 'none';
  static Basic = 'basic';
  static Premium = 'premium';
  static Expert = 'expert';

  static IsBasicOrHigher() {
    const license = LicenseTypes.getCurrent();
    return license !== LicenseTypes.None;
  }

  static IsPremiumOrHigher() {
    const license = LicenseTypes.getCurrent();
    return license === LicenseTypes.Premium || license === LicenseTypes.Expert;
  }

  static IsExpert() {
    const license = LicenseTypes.getCurrent();
    return license === LicenseTypes.Expert;
  }

  static getCurrent() {
    const {authStore} = rootStoreInstance;
    return authStore.user?.tenantLicense?.toLowerCase();
  }
}
