/* eslint-disable import/no-cycle */
import {sortBy} from 'lodash';
import {decorate, observable, computed, toJS} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import {Rate} from '../../models/rate';
import {TimeSpecification} from '../../models/timeSpecification';
import {LevelFees} from '../../models/levelFees';
import {Api} from '../../middleware/api';
import {BaseStore} from '../baseStore';
import {setStartOfMonth} from '../../config/moment';

export class RateStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.isInitialized = false;
  }
  /**
   * Should return all rates already filtered for the chosen canton on the UI.
   */
  get rates() {
    if (this.isLoading) {
      return [];
    }
    return sortBy(this.items, (item) => item.startDate);
  }

  createNew(startDate, cantonId = toJS(this.rootStore.cantonStore.currentCanton.id)) {
    const rateId = uuidv4();

    const levelFees = [];
    for (let index = 0; index <= 12; index += 1) {
      const fees = LevelFees.createFromPlainObject({
        id: uuidv4(),
        careLevel: index,
        residentFee: 0,
        KKFee: 0,
        cantonFee: 0,
        rateId,
      });
      levelFees.push(fees);
    }

    const timeSpecification = TimeSpecification.createFromPlainObject({
      id: uuidv4(),
      timeForCareLevel0: 0,
      timeForCareLevel1: 0,
      noKLVbound: 0,
      perLevel: 0,
      overhead: 0,
    });

    const newRate = Rate.createFromPlainObject({
      id: rateId,
      startDate: setStartOfMonth(startDate),
      endDate: null,
      cantonId,
      compensationFactor: 0,
      timeSpecification,
      levelFees,
    });

    // Only adds the new rate to the store if the canton already exists, otherwise it's added to the cantonStore.
    if (
      this.rootStore.cantonStore.currentCanton !== undefined
      && this.rootStore.cantonStore.currentCanton !== null
      && cantonId === toJS(this.rootStore.cantonStore.currentCanton.id)
    ) {
      super.add(newRate);
    }
    return newRate;
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.rate.getAllForCanton(toJS(this.rootStore.cantonStore.currentCanton.id));
    this.isInitialized = true;
    return serverResponse.data.map((item) => Rate.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.rate.saveAll(changedItems);
  }

  // Delete is not implemented for rates, since the history has to be stored.
}

decorate(RateStore, {
  rates: computed,
  /**
   * Rate currently displayed on the UI
   */
  currentRate: observable,
  isInitialized: observable,
});
