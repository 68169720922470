import {Spin} from 'antd';
import * as React from 'react';

const Spinner = ({fullwidth = true, style = {}, ...props}) => {
  if (fullwidth) {
    style = {
      ...style,
      textAlign: 'center',
      margin: '20px auto',
    };
  }

  return (
    <div style={style}>
      <Spin {...props} />
    </div>
  );
};

export default Spinner;
