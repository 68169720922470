import { decorate, observable, action, computed } from 'mobx';

export class ClientStore {
  breakpoint = 'xs'

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  get isMobile() {
    return this.breakpoint === 'xs';
  }

  setBreakpoint(breakpoint) {
    this.breakpoint = breakpoint;
  }
}

decorate(ClientStore, {
  breakpoint: observable,
  isMobile: computed,
  setBreakpoint: action,
});
