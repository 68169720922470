/* eslint-disable import/no-cycle */
import { executeGet, executePost, executePut } from '../requests';

const resourcesName = 'v1/recordedTimes';

export const RecordedTimeApi = {
  getRecordsLongerThan: (duration) => executeGet(`${resourcesName}/longerThan/${duration}`),
  deleteAll: (deletedIds) => executePost(`${resourcesName}/delete`, deletedIds),
  saveAll: (changedItems) => executePut(`${resourcesName}`, changedItems),
  saveRecord: (record) => executePut(`${resourcesName}/save`, record),
  updateRecord: (record) => executePut(`${resourcesName}/update`, record),
  getTimeLimit: () => executeGet(`${resourcesName}/getTimeLimit`),
  setTimeLimit: (limitInMin) => executePut(`${resourcesName}/setTimeLimit`, { limitInMin }),
  getRecords: (from, to) => executeGet(`${resourcesName}/getRecords`, { from, to }),
  getLatestRecordDate: () => executeGet(`${resourcesName}/getLatestRecordDate`),
};
