/* eslint-disable import/no-cycle */
import {rootStoreInstance} from '../stores/StoreContext';

export class Roles {
  /*
  To break dependency cycles, we have this values duplicated in the RootStore
  */
  static USER = 'user';
  static ADMIN = 'admin';
  static SUPERADMIN = 'superadmin';

  static getCurrent() {
    const {authStore} = rootStoreInstance;
    return authStore.user?.role;
  }

  static IsUser() {
    return Roles.getCurrent() === Roles.USER;
  }

  static IsAdmin() {
    return Roles.getCurrent() === Roles.ADMIN;
  }

  static IsSuperAdmin() {
    return Roles.getCurrent() === Roles.SUPERADMIN;
  }

  static IsAnyAdmin() {
    const current = Roles.getCurrent();
    return current === Roles.ADMIN || current === Roles.SUPERADMIN;
  }
}
