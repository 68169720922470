import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';
import {Area} from './area';

export class Facility extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.facilityStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      cantonId: toJS(this.cantonId),
      tenantId: toJS(this.tenantId),
      areas: this.areas.filter((area) => area.name !== '').map((area) => Area.createFromPlainObject(area).asJson()),
    };
  }

  areas = [];
}

decorate(Facility, {
  id: observable,
  name: observable,
  areas: observable,
  tenantId: observable,
  cantonId: observable,
  isActive: observable,
});
