import * as React from 'react';
import { decorate, observable } from 'mobx';
import { HeaderContent, HeaderContentItem } from '../components/shared/layouts/HeaderContent';

export const defaultContext = {
  navBarContent:
  <HeaderContent>
    <HeaderContentItem>
      <h1>PflegeZeit</h1>
    </HeaderContentItem>
  </HeaderContent>,
};

export class ContextStore {
  current = defaultContext;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }
}

decorate(ContextStore, {
  current: observable,
});
