/* eslint-disable import/no-cycle */
import {sortBy} from 'lodash';
import {decorate, observable, computed} from 'mobx';
import {Benchmark} from '../../models/benchmark';
import {Api} from '../../middleware/api';
import {BaseStore} from '../baseStore';

export class BenchmarkStore extends BaseStore {
  /**
   * Should return all existing benchmarks for the current tenant
   */
  get benchmarks() {
    if (this.isLoading) {
      return [];
    }
    return sortBy(this.items, (item) => item.sorting);
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.benchmark.getAll();
    return serverResponse.data.map((item) => Benchmark.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.benchmark.saveAll(changedItems);
  }

  async calculateBenchmak(timespan) {
    const res = await Api.benchmark.calculate(timespan);
    return res.data;
  }

  async loadLatestRecordDate() {
    if (this.lastRecordDate == null) {
      const res = await Api.recordedTime.getLatestRecordDate();
      this.lastRecordDate = res.data;
    }
  }
}

decorate(BenchmarkStore, {
  benchmarks: computed,
  /**
   * Currently selected benchmark on the UI
   */
  currentBenchmark: observable,
});
