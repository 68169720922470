/* eslint-disable import/no-cycle */
import { sortBy } from 'lodash';
import { decorate, observable, computed, toJS } from 'mobx';
import { RecordedTime } from '../../models/recordedTime';
import { Api } from '../../middleware/api';
import { BaseStore } from '../baseStore';

export class RecordedTimeStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.currentMinimalDurationInMinutes = 20;
  }

  timeLimitInMin = 45;

  /**
   * Should return all records over the current minimal duration for the current tenant
   */
  get records() {
    if (this.isLoading) {
      return [];
    }
    /**
     * Sorting helps the UI refreshing on changes
     */
    return sortBy(this.items, (item) => item.startTime);
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.recordedTime.getRecordsLongerThan(toJS(this.currentMinimalDurationInMinutes));
    return serverResponse.data.map((item) => RecordedTime.createFromPlainObject(item));
  }

  async loadAdditionalInfoAsync() {
    return Api.recordedTime.getTimeLimit();
  }

  showAdditionalInfo(info) {
    this.timeLimitInMin = info.data.timeLimitInMin;
  }

  async saveItemsAsync(changedItems) {
    await Api.recordedTime.saveAll(changedItems);
  }

  async saveDeletionsAsync(deletedIds) {
    await Api.recordedTime.deleteAll(deletedIds);
  }
  async getRecords(from, to) {
    return Api.recordedTime.getRecords(from, to);
  }
}

decorate(RecordedTimeStore, {
  records: computed,
  timeLimitInMin: observable,
  /**
   * Currently minimal duration set in the UI
   */
  currentMinimalDurationInMinutes: observable,
});
