export const normalizeResponseError = (response) => {
  const {data, status} = response;
  const error = {messages: []};
  if (!error.status) error.status = status || 500;

  let serverError = null;
  if (Array.isArray(data)) serverError = data;
  serverError = serverError || data.error || data.errors;

  if (typeof serverError === 'string') {
    error.messages.push(serverError);
  }
  if (Array.isArray(serverError)) {
    if (typeof serverError[0] === 'string') {
      error.messages.push(...serverError);
    } else if (typeof serverError[0] === 'object' && typeof serverError[0].message === 'string') {
      error.messages.push(...serverError.map((e) => e.message));
    }
  }
  return error;
};
