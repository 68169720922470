/* eslint-disable import/no-cycle */
import {executePost, executePut} from '../requests';

const resourcesName = 'v1/auth';

export const UserApi = {
  login: (params) => executePost(`${resourcesName}/login`, params),
  updateCredentials: (credentials, update) => executePut(`${resourcesName}/update`, {credentials, update}),
  refresh: () => executePut(`${resourcesName}/refresh`),
  logout: () => executePost(`${resourcesName}/logout`),
};
