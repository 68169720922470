/* eslint-disable import/no-cycle */
import {AuthStore} from './authStore';
import {ClientStore} from './clientStore';
import {ContextStore} from './contextStore';
import {ErrorStore} from './errorStore';
import {FacilityStore} from './basicUserStores/facilityStore';
import {ActivityStore} from './basicUserStores/activityStore';
import {ProfessionStore} from './basicUserStores/professionStore';
import {RecordedTimeStore} from './adminStores/recordedTimeStore';
import {TimeTrackingStore} from './basicUserStores/timeTrackingStore';
import {BenchmarkStore} from './adminStores/benchmarkStore';
import {CantonStore} from './adminStores/cantonStore';
import {RateStore} from './adminStores/rateStore';
import {ResidentStore} from './basicUserStores/residentStore';
import {ReportStore} from './adminStores/reportStore';
import {TenantStore} from './adminStores/tenantStore';
import {ResidentNullStore} from './basicUserStores/residentNullStore';

export class RootStore {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.errorStore = new ErrorStore(this);
    this.authStore = new AuthStore(this);
    this.clientStore = new ClientStore(this);
    this.contextStore = new ContextStore(this);
    if (this.authStore.authenticated && this.authStore.user.role.toUpperCase() === 'USER') {
      this.loadUserStores();
    }
    if (this.authStore.authenticated && this.authStore.user.role.toUpperCase() === 'ADMIN') {
      this.loadAdminStores();
    }
    if (this.authStore.authenticated && this.authStore.user.role.toUpperCase() === 'SUPERADMIN') {
      this.loadSuperadminStores();
    }
  }

  loadUserStores() {
    this.facilityStore = new FacilityStore(this);
    this.activityStore = new ActivityStore(this);
    this.professionStore = new ProfessionStore(this);
    this.timeTrackingStore = new TimeTrackingStore(this);
    if (
      this.authStore.user.tenantLicense.toUpperCase() === 'PREMIUM'
      || this.authStore.user.tenantLicense.toUpperCase() === 'EXPERT'
    ) {
      this.residentStore = new ResidentStore(this);
    } else {
      this.residentStore = new ResidentNullStore(this);
    }
  }

  loadAdminStores() {
    this.loadUserStores();

    this.recordedTimeStore = new RecordedTimeStore(this);
    this.benchmarkStore = new BenchmarkStore(this);
    this.cantonStore = new CantonStore(this);
    this.rateStore = new RateStore(this);
    this.reportStore = new ReportStore(this);
    this.tenantStore = new TenantStore(this);
  }

  loadSuperadminStores() {
    this.activityStore = new ActivityStore(this);
    this.professionStore = new ProfessionStore(this);
    this.benchmarkStore = new BenchmarkStore(this);
    this.cantonStore = new CantonStore(this);
    this.rateStore = new RateStore(this);
    this.tenantStore = new TenantStore(this);
  }

  clear() {
    this.initialize();
  }
}
