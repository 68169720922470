export class ResidentRecordedTimeFilter {
  ResidentRecordedTimeFilter() {
    this.areaId = '';
    this.residentId = null;
  }

  toQueryString() {
    return this.residentId === null ? this.areaId : `${this.areaId}_${this.residentId}`;
  }

  static createFromPlainObject(plainObject) {
    const model = new this();
    Object.assign(model, plainObject);
    return model;
  }
}
