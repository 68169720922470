/* eslint-disable import/no-cycle */
import {executeGet, executePost, executePut} from '../requests';

const resourcesName = 'v1/facilities';

export const FacilityApi = {
  getAll: () => executeGet(`${resourcesName}`),
  deleteAll: (deletedIds) => executePost(`${resourcesName}/delete`, deletedIds),
  saveAll: (changedItems) => executePut(`${resourcesName}`, changedItems),
};
