/* eslint-disable import/no-cycle */
import Qs from 'qs';
import axios from 'axios';
import config from '../config';
import {handleError} from './errors';

const resourcePath = (resource) => `${config.API_PATH}/${resource}`;

export const executeGet = (path, params = {}) =>
  axios
    .get(resourcePath(path), {
      params,
      paramsSerializer: (p) => Qs.stringify(p, {arrayFormat: 'brackets', encode: false}).replaceAll('[]', ''),
    })
    .then((response) => response)
    .catch(handleError);

export const executePatch = (path, params = {}) =>
  axios
    .patch(resourcePath(path), params)
    .then((response) => response)
    .catch(handleError);

export const executePost = (path, data, params = {}) =>
  axios
    .post(resourcePath(path), data, params)
    .then((response) => response)
    .catch(handleError);

export const executePut = (path, data, params = {}) =>
  axios
    .put(resourcePath(path), data, params)
    .then((response) => response)
    .catch(handleError);

export const executeDelete = (path, params = {}) =>
  axios
    .delete(resourcePath(path), params)
    .then((response) => response)
    .catch(handleError);
