/* eslint-disable import/no-cycle */
import { decorate, observable, action, toJS, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { RecordedTime } from '../../models/recordedTime';
import { Api } from '../../middleware/api';

export class TimeTrackingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    if (this.rootStore.authStore.user) {
      this.loadAsync();
    }
  }
  language = null;
  currentRecordedTime = null;
  currentFacility = null;
  currentArea = null;
  areaChosen = false;
  currentActivity = null;
  currentProfession = null;
  currentResident = null;
  residentChosen = false;
  isLoading = false;
  timeLimitInMin = 45;

  /**
   * Stops and submits the currently running activity from being recorded if there is any
   */
  async stopRecording() {
    if (this.currentRecordedTime !== null) {
      this.currentRecordedTime.endTime = new Date();
      await Api.recordedTime.updateRecord(this.currentRecordedTime.asJson());
    }
    this.currentRecordedTime = null;
  }

  stopLongerThanLimit() {
    let result = false;
    if (this.currentRecordedTime) {
      const startTime = moment(toJS(this.currentRecordedTime.startTime));
      const now = moment(new Date());

      const durationInMin = now.diff(startTime, 'minutes');
      if (durationInMin >= this.timeLimitInMin) {
        runInAction(() => {
          this.currentRecordedTime = null;
          this.currentFacility = null;
          this.currentArea = null;
          this.areaChosen = false;
          this.currentActivity = null;
          this.currentProfession = null;
          this.currentResident = null;
          this.residentChosen = false;
          result = true;
        });
      }
    }
    return result;
  }

  async loadAsync() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const serverResponse = await Api.recordedTime.getTimeLimit();
    runInAction(() => {
      this.timeLimitInMin = serverResponse.data.timeLimitInMin;
    });

    this.isLoading = false;
  }

  /**
   * Stops and submits the running activity if there is any
   * and starts a new with the selected information (on the other stores)
   */
  async startRecording() {
    const startTime = new Date();

    await this.stopRecording();

    this.currentRecordedTime = RecordedTime.createFromPlainObject({
      id: uuidv4(),
      facilityId: this.currentFacility.id,
      areaId: this.currentArea?.id ?? null,
      activityId: this.currentActivity.id,
      professionId: this.currentProfession.id,
      residentId: this.currentResident?.id ?? null,
      startTime,
      tenantId: toJS(this.rootStore.authStore.user.tenantId),
    });

    await Api.recordedTime.saveRecord(this.currentRecordedTime.asJson());
  }
  setLanguage(lng) {
    this.language = lng;
  }
}

decorate(TimeTrackingStore, {
  stopRecording: action,
  startRecording: action,
  /**
   * Currently being recorded time
   */
  currentRecordedTime: observable,

  /**
    * Selections for the next time tracking
    */
  currentFacility: observable,
  currentArea: observable,
  areaChosen: observable,
  currentActivity: observable,
  currentProfession: observable,

  /**
   * Activities can be recorded without residents
   * -> residentChosen === true && currentResident === null
   */
  currentResident: observable,
  residentChosen: observable,
  isLoading: observable,
  limitInMin: observable,
  language: observable,
});
