/* eslint-disable import/no-cycle */
import {decorate, computed, observable, toJS} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import {sortBy} from 'lodash';
import {Tenant} from '../../models/tenant';
import {Factor} from '../../models/factor';
import {Api} from '../../middleware/api';
import {BaseStore} from '../baseStore';
import {Roles} from '../../models/roles';
import {generatePassword} from '../../utils/helpers';
import {setStartOfMonth} from '../../config/moment';
import {LicenseTypes} from '../../models/licenseTypes';

export class TenantStore extends BaseStore {
  /**
   * Should return all tenants for the superadmin user
   * and just the actual tenant for the admin user of the current tenant
   */
  get tenants() {
    if (this.isLoading) {
      return [];
    }
    return sortBy(this.items, (item) => item.lastUpdate);
  }

  createNew() {
    this.newTenant = null;
    const tenantId = uuidv4();
    const adminId = uuidv4();
    const userId = uuidv4();

    // creates random usernames
    const randomNumber = Math.random().toString(36).slice(-3);
    const adminName = `admin${randomNumber}`;
    const userName = `user${randomNumber}`;

    // creates random passwords
    const adminPW = generatePassword();
    const userPW = generatePassword();

    const users = [
      {id: adminId, username: adminName, pw: adminPW, isAdmin: true, isSuperAdmin: false, tenantId},
      {id: userId, username: userName, pw: userPW, isAdmin: false, isSuperAdmin: false, tenantId},
    ];

    const newTenant = Tenant.createFromPlainObject({
      id: tenantId,
      name: '',
      lastUpdate: new Date(),
      users,
      timeLimitInMin: 45,
    });
    super.add(newTenant);
    this.newTenant = newTenant;
  }

  createNewFactor(startDate, tenant, oldWage) {
    if (LicenseTypes.IsExpert()) {
      const newFactor = Factor.createFromPlainObject({
        id: uuidv4(),
        startDate: setStartOfMonth(startDate),
        endDate: null,
        tenantId: toJS(this.rootStore.authStore.user.tenantId),
        hourlyWage: oldWage,
        comment: '',
        compensationFactor: 15.15,
      });
      if (!tenant.factors) {
        tenant.factors = [];
      }
      newFactor.hasChanges = true;
      tenant.factors.push(newFactor);
    }
  }

  async updateCredentials(credentials, users) {
    const serverResponse = await Api.user.updateCredentials(credentials, users);
    this.rootStore.authStore.setSessionUser(serverResponse.data);
  }

  async fetchItemsAsync() {
    const serverResponse = Roles.IsAdmin() ? await Api.tenant.getTenant() : await Api.tenant.getAll();
    return serverResponse.data.map((item) => Tenant.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    Roles.IsAdmin() ? await Api.tenant.saveTenant(changedItems[0]) : await Api.tenant.saveAll(changedItems);
  }

  async saveDeletionsAsync(deletedIds) {
    await Api.tenant.deleteAll(deletedIds);
  }
}

decorate(TenantStore, {
  tenants: computed,
  newTenant: observable,
});
