import moment from 'moment/moment';

export const humanizeSeconds = (secs) => {
  const d = Math.trunc(secs / 8.64e4);
  const H = Math.trunc((secs % 8.64e4) / 3.6e3);
  const m = Math.trunc((secs % 3.6e3) / 60);
  const z = (n) => (n < 10 ? '0' : '') + n;
  return `${d ? `${d}d` : ''} ${z(H)}:${z(m)}`;
};

export const humanizeMinutes = (minutes) => {
  const H = Math.trunc(minutes / 60);
  const m = Math.trunc(minutes % 60);
  const z = (n) => (n < 10 ? '0' : '') + n;
  return `${z(H)}:${z(m)}`;
};

export const computerizeMinutes = (stringInput) => {
  const splittedInput = stringInput.split(':');
  return parseInt(splittedInput[0], 10) * 60 + parseInt(splittedInput[1], 10);
};

export const humanizeSecondsHMS = (secs) => moment.utc(secs * 1000)
  .format(secs >= 3600 ? 'H[h]:mm[m]:ss[s]' : 'm[m]:ss[s]');

export const humanizeHoursDHM = (hours) => {
  const duration = moment.duration(hours, 'h');
  const prefix = hours >= 24 ? `${Math.floor(duration.asDays())}d ` : '';
  return `${prefix}${moment.utc(hours * 3600 * 1000)
    .format('H[h]:mm[m]')}`;
};
