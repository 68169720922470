import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import GlobalErrorBoundary from './components/errors/GlobalErrorBoundary';
import ScrollToTop from './components/shared/ScrollToTop';
import Spinner from './components/shared/spinners/Spinner';
import { Viewport } from './components/shared/Viewport';
import './config/moment';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
// noinspection JSCheckFunctionSignatures
const TimeTrackingRoutes = React.lazy(() => import('./components/timeTracking/Router'));
const AdminRoutes = React.lazy(() => import('./components/admin/Router'));
const LoginRoutes = React.lazy(() => import('./components/login/Router'));

function App() {
  return (
    <BrowserRouter>
      <GlobalErrorBoundary>
        <ScrollToTop />
        <Viewport />
        <ConfigProvider locale={deDE}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route path="/admin" component={AdminRoutes} />
              <Route path="/tracking" component={TimeTrackingRoutes} />
              <Route path="/" component={LoginRoutes} />
            </Switch>
          </Suspense>
        </ConfigProvider>
      </GlobalErrorBoundary>
    </BrowserRouter>
  );
}

export default observer(App);
