/* eslint-disable import/no-cycle */
import {computed, decorate, observable} from 'mobx';
import {BaseStore} from '../baseStore';

export class ResidentNullStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.names = [];
  }

  get residents() {
    return [];
  }

  async getNames() {
    return [];
  }

  createNewResident() {
    throw new Error('Residents not avilable');
  }

  createNewResidency() {
    throw new Error('Residents not avilable');
  }

  async findResident() {
    throw new Error('Residents not avilable');
  }

  async fetchItemsAsync() {
    return Promise.resolve([]);
  }

  async saveItemsAsync() {
    return Promise.resolve();
  }

  // Delete is not implemented for residents, since the history has to be stored.
}

decorate(ResidentNullStore, {
  residents: computed,
  names: observable,
  currentResident: observable,
  currentResidency: observable,
});
