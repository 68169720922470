import {reaction} from 'mobx';

export class BaseModel {
  id;
  hasChanges = false;
  translatedProperties = [];

  registerChangeHandler() {
    this.changeHandler = reaction(
      () => this.asJson(this),
      () => {
        this.hasChanges = true;
        if (this.entityStore) {
          this.entityStore.onChanges();
        }
      }
    );
  }

  dispose() {
    this.changeHandler();
  }

  /**
   * Allows to populate referenced values from their respective store.
   * Goal: Object-graph is properly initialized and no copies are around. I.e.
   * changing sub properties will not only change the local copy.
   */
  populateAttributesFromStore() {}

  asJson() {
    throw new Error('Implement as Json');
  }

  attachToStore(rootStore, entityStore) {
    this.rootStore = rootStore;
    this.entityStore = entityStore;
    this.populateAttributesFromStore();
    this.registerChangeHandler();
  }

  static createFromPlainObject(plainObject) {
    const model = new this();
    Object.assign(model, plainObject);
    return model;
  }

  /**
   * Creates a model instance from a plain JavaScript object. A model instance is part of the mobx store.
   * @param plainObject
   * @param rootStore
   * @param skipPopulate Set to true if attributes should not be populated from store. This is only necessary in places
   * where the store is not yet properly initialized. Defaults to false.
   * @return {BaseModel}
   */
  static fromPlainObject(plainObject, rootStore, skipPopulate = false) {
    const model = new this(rootStore);
    Object.assign(model, plainObject);
    if (!skipPopulate) {
      model.populateAttributesFromStore(rootStore);
    }
    if (model.translatedProperties.length) {
      // Transform translation objects to a single object, keyed by language
      model.translatedProperties.forEach((prop) => {
        const propTranslation = {};
        model.translations.forEach((translation) => {
          propTranslation[translation.language] = translation[prop];
        });
        model[`translated_${prop}`] = propTranslation;
      });
    }
    return model;
  }

  /**
   * Extracts all translated fields from form values and returns an array of translations.
   * @param {{[string]: any}} values
   * @return {*[]}
   */
  static convertToSavableTranslations(values) {
    const model = new this();
    const savableTranslations = [];
    model.translatedProperties.forEach((prop) => {
      const translatedProp = values[`translated_${prop}`] || {};
      Object.keys(translatedProp).forEach((language) => {
        const currentTranslation = savableTranslations.find((t) => t.language === language);
        if (currentTranslation) {
          currentTranslation[prop] = translatedProp[language];
        } else {
          savableTranslations.push({
            language,
            [prop]: translatedProp[language],
          });
        }
      });
    });
    return savableTranslations;
  }
}
