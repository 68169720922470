/* eslint-disable import/no-cycle */
import { decorate, observable } from 'mobx';
import { maxBy, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Activity } from '../../models/activity';
import { Api } from '../../middleware/api';
import { BaseStore } from '../baseStore';

export class ActivityStore extends BaseStore {
  // Eager loading set to true in order to load the data on start
  constructor(rootStore) {
    super(rootStore, true);
    this.showInactiveInEditor = false;
  }

  getActivities(parentId, onlyActives = true) {
    if (this.isLoading) {
      return [];
    }
    return sortBy(
      this.items.filter((activity) => (activity.isActive || !onlyActives)
        && (parentId ? activity.parentId === parentId : !activity.parentId)),
      (item) => item.sorting
    );
  }

  getActivity(id, onlyActives = true) {
    if (this.isLoading) return undefined;

    return this.items.find((activity) => activity.id === id && (activity.isActive || !onlyActives));
  }

  getAllActivities(onlyActives = true) {
    if (this.isLoading) {
      return [];
    }
    return sortBy(
      this.items.filter((activity) => activity.isActive || !onlyActives),
      (item) => item.sorting
    );
  }

  createNew(parentId, translations) {
    const maxValueIdem = maxBy(this.items, (f) => f.sorting);
    const newSort = maxValueIdem !== 'undefined' ? maxValueIdem.sorting + 1 : 0;
    const saveableTranslations = Activity.convertToSavableTranslations(translations);
    super.add(
      Activity.createFromPlainObject({
        id: uuidv4(),
        sorting: newSort,
        parentId,
        color: '#E8E8E8',
        isActive: true,
        translations: saveableTranslations,
      })
    );
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.activity.getAll();
    return serverResponse.data.map((item) => Activity.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.activity.saveAll(changedItems);
  }

  toggleShowInactiveInEditor() {
    this.showInactiveInEditor = !this.showInactiveInEditor;
  }

  // Deletion not possible, only setting activites to inactive
}

decorate(ActivityStore, {
  /**
   * If the super admin editor should show "deleted" activities
   */
  showInactiveInEditor: observable,
});
