/* eslint-disable import/no-cycle */
import {executeGet, executePost, executePut} from '../requests';

const resourcesName = 'v1/rates';

export const RateApi = {
  getAllForCanton: (id) => executeGet(`${resourcesName}/${id}`),
  deleteAll: (deletedIds) => executePost(`${resourcesName}/delete`, deletedIds),
  saveAll: (changedItems) => executePut(`${resourcesName}`, changedItems),
};
