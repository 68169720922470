export class HourAnalysis {
  datesToDisplay = [];
  start = null;
  end = null;
  hourAnalysisValues = [];

  static createFromPlainObject(plainObject) {
    const model = new this();
    Object.assign(model, plainObject);
    return model;
  }
}
