import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';

export class Area extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.facilityStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      facilityId: toJS(this.facilityId),
    };
  }
}

decorate(Area, {
  id: observable,
  name: observable,
  facilityId: observable,
});
