import {action, decorate, observable, onBecomeObserved, onBecomeUnobserved, toJS} from 'mobx';
import moment from 'moment';
import {BaseModel} from './baseModel';
import {humanizeSecondsHMS} from '../utils/time';

export class RecordedTime extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.recordedTimeStore);
    onBecomeObserved(this, 'elapsed', this.watchElapsed);
    onBecomeUnobserved(this, 'elapsed', this.suspendElapsed);
  }

  watchElapsed = () => {
    this.elapsed = '0m:00s';
    this.interval = setInterval(() => {
      if (this.startTime) {
        const seconds = moment().diff(this.startTime, 's');
        this.elapsed = humanizeSecondsHMS(seconds);
      } else {
        this.elapsed = '--';
      }
    }, 1000);
  }

  suspendElapsed = () => {
    this.elapsed = '--';
    clearInterval(this.interval);
  }

  asJson() {
    return {
      id: toJS(this.id),
      facilityId: toJS(this.facilityId),
      areaId: toJS(this.areaId) ? toJS(this.areaId) : undefined,
      activityId: toJS(this.activityId),
      professionId: toJS(this.professionId),
      startTime: toJS(this.startTime),
      endTime: toJS(this.endTime),
      tenantId: toJS(this.tenantId),
      residentId: this.residentId ? toJS(this.residentId) : undefined,
    };
  }
}

decorate(RecordedTime, {
  id: observable,
  facilityId: observable,
  areaId: observable,
  activityId: observable,
  professionId: observable,
  residentId: observable,
  startTime: observable,
  endTime: observable,
  tenantId: observable,
  elapsed: observable,
  watchElapsed: action,
  suspendElapsed: action,
});
