/**
 * Moves the item from position (index 'from') to position (index 'to') in the array. Clones the array
 * and returns the new array. The given array is not mutated.
 * @param array original array
 * @param from index of the items origin position
 * @param to index of the items target position
 * @return clone of the array with moved item
 */
export const arrayMove = (array, from, to) => {
  array = [...array];
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
  return array;
};

/**
 * Replaces all undefined entries in an object with null. It only works on the objects first level.
 *
 * @param originalObject
 * @return {{}}
 */
export const replaceUndefWithNull = (originalObject) => {
  const objectClone = {...originalObject};

  Object.keys(originalObject).forEach((key) => {
    objectClone[key] = originalObject[key] || null;
  });

  return objectClone;
};

/**
 * Transforms the first character in a string to lower case
 *
 * @param string
 * @return {string}
 */
export const firstCharToLowerCase = (string) => string.charAt(0).toLowerCase() + string.slice(1);

/**
 * Generates random password
 *
 * @param length
 * @param wishlist
 * @return {string}
 */
export const generatePassword = (
  length = 12,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) => Array.from(crypto.getRandomValues(new Uint32Array(length)))
  .map((x) => wishlist[x % wishlist.length])
  .join('');
