import {decorate, observable} from 'mobx';
import {BaseModel} from './baseModel';

export class User extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.authStore);
  }

  static createFromPlainObject(rootStore, plainObject) {
    return super.createFromPlainObject(rootStore, rootStore.authStore, plainObject);
  }
}

decorate(User, {
  id: observable,
  username: observable,
  role: observable,
  tenantId: observable,
  tenantLicense: observable,
});
