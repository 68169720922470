/* eslint-disable import/no-cycle */
import {decorate, observable, toJS, reaction, runInAction} from 'mobx';
import {getRightBefore, getRightAfter, officialDatetoUTC} from '../../config/moment';
import {Api} from '../../middleware/api';
import {HourAnalysis} from '../../models/hourAnalysis';
import {RateAnalysisValue} from '../../models/rateAnalysisValue';
import {BenchmarkAnalysisValue} from '../../models/benchmarkAnalysisValue';
import {LicenseTypes} from '../../models/licenseTypes';
import {ResidentRecordedTimeFilterWithName} from '../../models/residentRecordedTimeFilterWithName';

export class ReportStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    const today = new Date();
    const oneMonthBefore = getRightBefore(today, 'months');
    // Add one day to have just a month
    const fromDate = officialDatetoUTC(getRightAfter(oneMonthBefore, 'days'));
    this.from = new Date(fromDate);
    this.to = today;
    this.selectedEntityIds = [];
    this.selectedProfessionIds = [];
    this.currentHourReport = null;
    this.currentBenchmarkReport = [];
    this.currentRai = 0;
    this.isLoadingData = false;
    this.isLoading = false;
    this.isLoadingFilter = false;
    this.isInitialized = false;
    this.hasData = false;
    this.registerChangeHandler();
  }

  registerChangeHandler() {
    this.changeHandlers = [
      reaction(
        () => [this.from, this.to],
        () => {
          this.loadAndConsolidateFilter();
        }
      ),
      reaction(
        () => [this.selectedEntityIds, this.selectedProfessionIds],
        () => {
          this.loadData();
        }
      ),
    ];
  }

  dispose() {
    this.changeHandlers.forEach((element) => element());
  }

  async loadAndConsolidateFilter() {
    await this.loadFilter();

    const newSelectedEntityIds = [];

    this.selectedEntityIds.forEach((idAndType) => {
      const type = idAndType.substring(0, 1);
      const id = idAndType.substring(1);

      // readd all filters if the resident is still there in the same timespan
      if (type !== 'r' || this.residencyFilter.find((filter) => filter.toQueryString() === id)) {
        newSelectedEntityIds.push(idAndType);
      }
    });

    runInAction(() => {
      this.selectedEntityIds = newSelectedEntityIds;
    });

    this.loadData();
  }

  async loadAsync() {
    if (!this.isLoading) {
      await this.loadFilter();
      const professions = await this.rootStore.professionStore.fetchItemsAsync();
      runInAction(() => {
        if (!this.isInitialized) {
          this.selectedEntityIds = this.rootStore.facilityStore.facilities.map((f) => `f${f.id}`);
          this.selectedProfessionIds = professions.map((p) => p.id);
          this.isInitialized = true;
        }
        this.isLoading = false;
      });
    }
  }

  async loadFilter() {
    if (!this.isLoadingFilter && this.from && this.to) {
      this.isLoadingFilter = true;

      const serverResponse = await Api.report.getResidencyFilter(toJS(this.from), toJS(this.to));

      runInAction(() => {
        this.residencyFilter = serverResponse.data.map((item) =>
          ResidentRecordedTimeFilterWithName.createFromPlainObject(item));
        this.isLoadingFilter = false;
      });
    }
  }

  async loadData() {
    if (!this.isLoadingData && this.from && this.to && this.selectedEntityIds && this.selectedEntityIds.length > 0) {
      this.isLoadingData = true;
      this.error = undefined;
      await this.loadBenchmarkAnalysis();
      await this.getRaiValue();
      await this.loadHourAnalysis();
      if (LicenseTypes.IsPremiumOrHigher) {
        try {
          await this.loadRateAnalysis();
        } catch (e) {
          this.error = e;
        }
      }
      this.isLoadingData = false;
      this.hasData = true;
    }
  }

  async loadHourAnalysis() {
    const {facilityIds, areaIds, professionIds, residentFilters} = this.getQueryValues();
    const serverResponse = await Api.report.getHourAnalysis(
      toJS(this.from),
      toJS(this.to),
      facilityIds,
      areaIds,
      professionIds,
      residentFilters
    );

    runInAction(() => {
      this.currentHourReport = HourAnalysis.createFromPlainObject(serverResponse.data);
    });
  }

  async loadBenchmarkAnalysis() {
    const {facilityIds, areaIds, professionIds, residentFilters} = this.getQueryValues();
    const serverResponse = await Api.report.getBenchmarkAnalysis(
      toJS(this.from),
      toJS(this.to),
      facilityIds,
      areaIds,
      professionIds,
      residentFilters
    );
    runInAction(() => {
      this.currentBenchmarkReport = serverResponse.data.map((benchmarkValue) =>
        BenchmarkAnalysisValue.createFromPlainObject(benchmarkValue));
    });
  }

  async getRaiValue() {
    const {facilityIds, areaIds, residentFilters} = this.getQueryValues();
    const serverResponse = await Api.report.getRai(
      toJS(this.from),
      toJS(this.to),
      facilityIds,
      areaIds,
      residentFilters
    );
    runInAction(() => {
      this.currentRai = serverResponse.data;
    });
  }

  async loadRateAnalysis() {
    const {facilityIds, areaIds, residentFilters} = this.getQueryValues();
    const serverResponse = await Api.report.getRateAnalysis(
      toJS(this.from),
      toJS(this.to),
      facilityIds,
      areaIds,
      residentFilters
    );

    runInAction(() => {
      this.currentRateReport = serverResponse.data.map((rateValue) =>
        RateAnalysisValue.createFromPlainObject(rateValue));
    });
  }

  getRecordsForParentActivity(name) {
    const parentActivity = this.rootStore.activityStore
      .getAllActivities(false)
      .find((a) => a.translations.find((translation) => translation.language === 'de-CH')?.name.toUpperCase() === name);
    const childrenActivities = this.rootStore.activityStore.getActivities(parentActivity.id, false);
    // no children activities
    if (childrenActivities.length === 0) {
      return this.currentHourReport.hourAnalysisValues
        .filter((value) => value.activity.id.toUpperCase() === parentActivity.id.toUpperCase())
        .flatMap((v) => v.timePerDay);
    }
    const aggregatedForParentActivity = [];
    childrenActivities.forEach((activity) => {
      const valuesForActivity = this.currentHourReport.hourAnalysisValues.filter(
        (entry) => entry.activity.id.toUpperCase() === activity.id.toUpperCase()
      );
      aggregatedForParentActivity.push(...valuesForActivity);
    });

    const allValuesForParentWithDate = aggregatedForParentActivity.flatMap((v) => v.timePerDay);

    return this.currentHourReport.datesToDisplay.map((date) => {
      const dailyTotal = allValuesForParentWithDate
        .filter((value) => value.x.valueOf() === date.valueOf())
        .map((value) => value.y)
        .reduce((a, b) => a + b, 0);
      return {x: date, y: dailyTotal};
    });
  }

  getQueryValues() {
    const facilityIds = [];
    const areaIds = [];
    const residentFilters = [];

    this.selectedEntityIds.forEach((idAndType) => {
      const type = idAndType.substring(0, 1);
      const id = idAndType.substring(1);

      if (type === 'f') {
        facilityIds.push(id);
      } else if (type === 'a') {
        areaIds.push(id);
      } else {
        residentFilters.push(id);
      }
    });

    return {
      facilityIds: toJS(facilityIds),
      areaIds: toJS(areaIds),
      professionIds: toJS(this.selectedProfessionIds),
      residentFilters: toJS(residentFilters),
    };
  }
}

decorate(ReportStore, {
  from: observable,
  to: observable,
  /**
   * Ids of selected facilities, areas or residents
   */
  selectedEntityIds: observable,
  selectedProfessionIds: observable,
  currentHourReport: observable,
  currentBenchmarkReport: observable,
  currentRai: observable,
  currentRateReport: observable,
  isLoadingFilter: observable,
  residencyFilter: observable,
  hasData: observable,
  isLoadingData: observable,
  isLoading: observable,
  isInitialized: observable,
  error: observable,
});
