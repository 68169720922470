import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';
import {LevelFees} from './levelFees';
import {TimeSpecification} from './timeSpecification';

export class Rate extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.rateStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      startDate: toJS(this.startDate),
      endDate: this.endDate !== null ? toJS(this.endDate) : undefined,
      cantonId: toJS(this.cantonId),
      timeSpecification: TimeSpecification.createFromPlainObject(this.timeSpecification).asJson(),
      levelFees: this.levelFees.map((fees) => LevelFees.createFromPlainObject(fees).asJson()),
      compensationFactor: toJS(this.compensationFactor),
    };
  }

  levelFees = [];
}

decorate(Rate, {
  id: observable,
  startDate: observable,
  endDate: observable,
  cantonId: observable,
  compensationFactor: observable,
  timeSpecification: observable,
  levelFees: observable,
});
