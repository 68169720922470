export class RateAnalysisValue {
  date = null;
  residents = null;
  rai = null;
  careTimeRate = null;
  overheadTimeRate = null;
  totalFee = null;
  compensationFactor = null;
  hourlyWage = null;

  static createFromPlainObject(plainObject) {
    const model = new this();
    Object.assign(model, plainObject);
    return model;
  }
}
