import { createBreakpoint, useDebounce } from 'react-use';

import cssBreakpoints from '../../assets/stylesheets/breakpoints.module.scss';
import { useStore } from '../../hooks/useStore';

// Need to convert breakpoints values into integer because scss provides only strings
export const breakpoints = Object.keys(cssBreakpoints).reduce((acc, value) => ({
  ...acc,
  [value]: parseInt(cssBreakpoints[value], 10),
}), {});

export const useBreakpoint = createBreakpoint(breakpoints);

export const Viewport = () => {
  const store = useStore();
  const breakpoint = useBreakpoint();

  useDebounce(() => {
    store.clientStore.setBreakpoint(breakpoint);
  }, 250, [breakpoint]);

  return null;
};
