/* eslint-disable import/no-cycle */
import {ProfessionApi} from './endpoints/professionApi';
import {ActivityApi} from './endpoints/activityApi';
import {FacilityApi} from './endpoints/facilityApi';
import {RecordedTimeApi} from './endpoints/recordedTimeApi';
import {UserApi} from './endpoints/userApi';
import {BenchmarkApi} from './endpoints/benchmarkApi';
import {CantonApi} from './endpoints/cantonApi';
import {RateApi} from './endpoints/rateApi';
import {ResidentApi} from './endpoints/residentApi';
import {ReportApi} from './endpoints/reportApi';
import {ErrorApi} from './endpoints/errorApi';
import {TenantApi} from './endpoints/tenantApi';

export const Api = {
  profession: ProfessionApi,
  activity: ActivityApi,
  facility: FacilityApi,
  recordedTime: RecordedTimeApi,
  benchmark: BenchmarkApi,
  canton: CantonApi,
  rate: RateApi,
  user: UserApi,
  resident: ResidentApi,
  report: ReportApi,
  error: ErrorApi,
  tenant: TenantApi,
};
