/* eslint-disable import/no-cycle */
import {decorate, observable} from 'mobx';
import {Api} from '../middleware/api';

export class ErrorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.error = null;
  }

  sendError(location, info) {
    Api.error.sendErrorAsync(location, JSON.stringify(info, undefined, 4));
  }
}

decorate(ErrorStore, {
  error: observable,
});
