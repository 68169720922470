import { decorate, observable, toJS } from 'mobx';
import { BaseModel } from './baseModel';

export class Profession extends BaseModel {
  translatedProperties = ['name'];

  constructor(rootStore) {
    super(rootStore, (root) => root.professionStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      sorting: toJS(this.sorting),
      isActive: toJS(this.isActive),
      translations: toJS(this.translations),
    };
  }
}

decorate(Profession, {
  id: observable,
  sorting: observable,
  isActive: observable,
  translations: observable,
});
