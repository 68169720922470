export class BenchmarkAnalysisValue {
  activityId = null;
  professionId = null;
  value = 0;

  static createFromPlainObject(plainObject) {
    const model = new this();
    Object.assign(model, plainObject);
    return model;
  }
}
