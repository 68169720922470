import * as React from 'react';
import {Button as AntdButton} from 'antd';
import ButtonLink from './ButtonLink';

const Button = React.forwardRef(({to, ...props}, ref) => {
  if (to) {
    return <ButtonLink {...props} to={to} ref={ref}/>;
  }

  return (
    <AntdButton {...props} ref={ref}/>
  );
});

export default Button;
