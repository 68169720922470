import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';

export class Residency extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.benchmarkStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      careLevel: toJS(this.careLevel),
      startDate: toJS(this.startDate),
      endDate: this.endDate ? toJS(this.endDate) : undefined,
      facilityId: toJS(this.facilityId),
      areaId: toJS(this.areaId),
      residentId: toJS(this.residentId),
    };
  }
}

decorate(Residency, {
  id: observable,
  careLevel: observable,
  startDate: observable,
  endDate: observable,
  facilityId: observable,
  areaId: observable,
  residentId: observable,
});
