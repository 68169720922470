import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';

export class LevelFees extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.rateStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      careLevel: toJS(this.careLevel),
      residentFee: toJS(this.residentFee),
      KKFee: toJS(this.KKFee),
      cantonFee: toJS(this.cantonFee),
      rateId: toJS(this.rateId),
    };
  }
}

decorate(LevelFees, {
  id: observable,
  careLevel: observable,
  residentFee: observable,
  KKFee: observable,
  cantonFee: observable,
  rateId: observable,
});
