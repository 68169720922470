/* eslint-disable import/no-cycle */
import {sortBy} from 'lodash';
import {decorate, computed, observable, toJS} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import {Resident} from '../../models/resident';
import {Residency} from '../../models/residency';
import {Api} from '../../middleware/api';
import {BaseStore} from '../baseStore';
import {Roles} from '../../models/roles';

export class ResidentStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.names = [];
  }
  /**
   * Should return all actual residents for the current tenant
   */
  get residents() {
    if (this.isLoading) {
      return [];
    }
    return sortBy(this.items, (item) => item.name);
  }

  async getNames() {
    const serverResponse = await Api.resident.getAllNames();
    const data = serverResponse.data.map((item) => ({id: item.id, name: item.name}));
    return data;
  }

  createNewResident(name, id = null) {
    const newResident = Resident.createFromPlainObject({
      id: id || uuidv4(),
      name: name.toUpperCase(),
      residencies: [],
      tenantId: toJS(this.rootStore.authStore.user.tenantId),
    });

    super.add(newResident);
    return newResident;
  }

  createNewResidency(facility, area, level, startDate, residentId = null) {
    const newResidency = Residency.createFromPlainObject({
      id: uuidv4(),
      careLevel: level,
      startDate: new Date(startDate),
      facilityId: facility,
      areaId: area,
      residentId: residentId || this.currentResident.id,
    });
    return newResidency;
  }

  async findResident(name) {
    const serverResponse = await Api.resident.getByName(name);
    const oldResident = Resident.createFromPlainObject(serverResponse.data);
    super.add(oldResident);
    return oldResident;
  }

  async fetchItemsAsync() {
    this.names = Roles.IsAdmin() ? await this.getNames() : null;
    const serverResponse = await Api.resident.getAllCurrent();
    return serverResponse.data.map((item) => Resident.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.resident.saveAll(changedItems);
  }

  // Delete is not implemented for residents, since the history has to be stored.
}

decorate(ResidentStore, {
  residents: computed,
  names: observable,
  /**
   * Resident currently selected on the *admin* UI
   */
  currentResident: observable,
  /**
   * Residency currently selected on the *admin* UI
   */
  currentResidency: observable,
});
