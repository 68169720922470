import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './locales/de.json';
import fr from './locales/fr.json';
import somedConfig from '../utils/somedConfig';

export const DEFAULT_LANGUAGE = 'de';
export const AVAILABLE_LANGUAGES = [DEFAULT_LANGUAGE, 'fr'];

// ALSO see https://ant.design/docs/react/i18n and App.js
i18n
  .use(initReactI18next)// passes i18n down to react-i18next
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
    },
    load: 'all',
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false, // process.env.NODE_ENV !== 'production',
    whitelist: AVAILABLE_LANGUAGES,
    nonExplicitWhitelist: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeparator: '-::-',
    returnObjects: true,
    lng: somedConfig.language,
  });

export default i18n;
