import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';

export class TimeSpecification extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.rateStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      timeForCareLevel0: toJS(this.timeForCareLevel0),
      timeForCareLevel1: toJS(this.timeForCareLevel1),
      noKLVbound: toJS(this.noKLVbound),
      perLevel: toJS(this.perLevel),
      overhead: toJS(this.overhead),
    };
  }
}

decorate(TimeSpecification, {
  id: observable,
  timeForCareLevel0: observable,
  timeForCareLevel1: observable,
  noKLVbound: observable,
  perLevel: observable,
  overhead: observable,
});
