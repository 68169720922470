import {Dropdown, Menu} from 'antd';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {DownOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {Roles} from '../../../../models/roles';
import {useStore} from '../../../../hooks/useStore';

const Account = () => {
  const {t} = useTranslation();

  const store = useStore();
  const {authStore} = store;

  const logout = () => {
    authStore.logout();
  };

  const menu = (
    <Menu className="user-menu" selectedKeys={[]}>
      <Menu.Item key="logout" onClick={logout}>
        <Link to="/">{t('links.logout')}</Link>
      </Menu.Item>
      {authStore.user.role !== Roles.USER ? (
        <Menu.Item>
          <Link to="/admin">Admin</Link>
        </Menu.Item>
      ) : null}
      {authStore.user.role !== Roles.SUPERADMIN ? (
        <Menu.Item>
          <Link to="/tracking">User</Link>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <Dropdown overlay={authStore.user ? menu : null} trigger={['click']}>
      <div className="user-menu menu-item" style={{padding: '0 5px', cursor: 'pointer', lineHeight: 'normal'}}>
        <span style={{fontSize: 14, color: '#1890FF', fontWeight: 500}}>
          {store.authStore.user ? store.authStore.user.username : null}
        </span>
        <DownOutlined style={{marginLeft: 8, color: 'rgba(0, 0, 0, 0.25)', fontSize: 12, verticalAlign: 'middle'}} />
      </div>
    </Dropdown>
  );
};

export default observer(Account);
