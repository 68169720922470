/* eslint-disable import/no-cycle */
import {executeGet, executePost, executePut} from '../requests';

const resourcesName = 'v1/tenants';

export const TenantApi = {
  getAll: () => executeGet(`${resourcesName}`),
  getTenant: () => executeGet(`${resourcesName}/single`),
  deleteAll: (deletedIds) => executePost(`${resourcesName}/delete`, deletedIds),
  saveTenant: (changedItem) => executePut(`${resourcesName}/single`, changedItem),
  saveAll: (changedItems) => executePut(`${resourcesName}`, changedItems),
};
