import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';
import {BenchmarkValue} from './benchmarkValue';

export class Benchmark extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.benchmarkStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      lastUpdate: toJS(this.lastUpdate),
      calculated: toJS(this.calculated),
      active: toJS(this.active),
      calculationStart: toJS(this.calculationStart),
      calculationEnd: toJS(this.calculationEnd),
      calculationTenantAmount: toJS(this.calculationTenantAmount),
      rai: toJS(this.rai),
      benchmarkValues: this.benchmarkValues.map((value) => BenchmarkValue.createFromPlainObject(value).asJson()),
    };
  }

  benchmarkValues = [];
}

decorate(Benchmark, {
  id: observable,
  name: observable,
  lastUpdate: observable,
  calculated: observable,
  active: observable,
  calculationStart: observable,
  calculationEnd: observable,
  calculationTenantAmount: observable,
  rai: observable,
  benchmarkValues: observable,
});
