import { useContext, useEffect } from 'react';
import { StoreContext } from '../stores/StoreContext';

export const useStore = () => useContext(StoreContext);

export const useStoreLoad = (effect) => {
  const store = useStore();

  useEffect(effect, [store.authStore.user?.id]);
};
