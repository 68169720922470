/* eslint-disable import/no-cycle */
import {sortBy} from 'lodash';
import {decorate, observable, computed} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import {Canton} from '../../models/canton';
import {Api} from '../../middleware/api';
import {BaseStore} from '../baseStore';

export class CantonStore extends BaseStore {
  get cantons() {
    if (this.isLoading) {
      return [];
    }
    return sortBy(this.items, (item) => item.name);
  }

  createNew(name) {
    const newId = uuidv4();
    const rates = [];
    rates.push(this.rootStore.rateStore.createNew(new Date(), newId));
    const newCanton = Canton.createFromPlainObject({id: newId, name, rates});
    super.add(newCanton);
    return newCanton;
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.canton.getAll();
    return serverResponse.data.map((item) => Canton.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.canton.saveAll(changedItems);
  }

  // Delete is not implemented for cantons, since the history has to be stored.
}

decorate(CantonStore, {
  cantons: computed,
  /**
   * Currently selected canton on the UI
   */
  currentCanton: observable,
});
