import { forEach, remove } from 'lodash';
import { decorate, observable, runInAction, toJS } from 'mobx';

export class BaseStore {
  constructor(rootStore, eager = false) {
    this.rootStore = rootStore;
    if (eager && this.rootStore.authStore.user) {
      this.loadAsync();
    }
  }

  items = [];
  deletedIds = [];
  isLoading = false;
  hasData = false;

  onChanges() {
    this.hasChanges = true;
  }

  async loadAsync() {
    if (!this.hasData && !this.isLoading) {
      this.isLoading = true;
      const loadedItems = await this.fetchItemsAsync();
      const response = await this.loadAdditionalInfoAsync();

      runInAction(() => {
        this.showAdditionalInfo(response);

        this.items.clear();
        forEach(loadedItems, (item) => {
          item.attachToStore(this.rootStore, this);
          this.items.push(item);
        });
        this.resetChangeFlags();
        this.isLoading = false;
        this.hasData = true;
      });
    }
  }

  deleteById(id) {
    const removedItems = remove(this.items, (item) => item.id === id);
    forEach(removedItems, (item) => {
      item.dispose();
      this.deletedIds.push(item.asJson().id);
    });
    this.onChanges();
  }

  add(item) {
    runInAction(() => {
      item.hasChanges = true;
      this.items.push(item);
      item.attachToStore(this.rootStore, this);
      this.hasChanges = true;
    });
  }

  discardChangesAsync() {
    runInAction(async () => {
      this.deletedIds.clear();
      this.hasData = false;
      await this.loadAsync().then(() => this.resetChangeFlags());
    });
  }

  async commitChangesAsync() {
    const changes = this.items.filter((item) => item.hasChanges).map((item) => item.asJson());
    if (changes.length > 0) {
      await this.saveItemsAsync(changes);
      this.hasData = false;
      await this.loadAsync();
    }
    if (this.deletedIds.length > 0) {
      await this.saveDeletionsAsync(toJS(this.deletedIds));
      this.deletedIds.clear();
    }
    this.resetChangeFlags();
  }

  /**
     * Virtual asynchronous method to load additional info from the server.
     * Should return a Promise containing the result that will be passed to showAdditionalInfo
     */

  /* eslint-disable */
  /* Justification: "Virtual style method defintion => so empty method is ok as well as unused info parameter" */
  async loadAdditionalInfoAsync() {

  }

  /**
    * Sets loaded info (by loadAdditionalInfoAsync) within a MobX runInAction at the same time as loaded items.
    */
  showAdditionalInfo(info) {

  }
  /* eslint-enable */

  /**
   * Abstract asynchronous method to load all items from the server.
   * Should return a Promise containing all items
   */
  async fetchItemsAsync() {
    throw new Error('Abstract method fetchItemsAsync not implemented');
  }

  /**
   * Abstract asynchronous method to save all items to the server.
   */
  async saveItemsAsync(changedItems) {
    throw new Error(`Abstract method saveItemsAsync not implemented but called with ${changedItems}`
      + 'Is the store readonly?');
  }

  async saveDeletionsAsync(deletedIds) {
    throw new Error(`Abstract method saveDeletionsAsync not implemented but called with ${deletedIds}`
      + 'Is the store readonly?');
  }

  resetChangeFlags() {
    runInAction(() => {
      forEach(this.items, (item) => {
        item.hasChanges = false;
      });
      this.hasChanges = false;
    });
  }
}

decorate(BaseStore, {
  hasChanges: observable,
  items: observable,
  deletedIds: observable,
  isLoading: observable,
  hasData: observable,
});
