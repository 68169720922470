import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';

export class BenchmarkValue extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.benchmarkStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      value: toJS(this.value),
      activityId: toJS(this.activityId),
      benchmarkId: toJS(this.benchmarkId),
    };
  }
}

decorate(BenchmarkValue, {
  id: observable,
  value: observable,
  activityId: observable,
  benchmarkId: observable,
});
