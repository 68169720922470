/* eslint-disable import/no-cycle */
import {decorate, computed, toJS} from 'mobx';
import {v4 as uuidv4} from 'uuid';
import {sortBy} from 'lodash';
import {Facility} from '../../models/facility';
import {Area} from '../../models/area';
import {Api} from '../../middleware/api';
import {BaseStore} from '../baseStore';

export class FacilityStore extends BaseStore {
  // Eager loading set to true in order to load the data on start
  constructor(rootStore) {
    super(rootStore, true);
  }

  /**
   * Should return all facilities already filtered for the tenant of the current user
   */
  get facilities() {
    if (this.isLoading) {
      return [];
    }
    return sortBy(this.items, (item) => item.id);
  }

  createNew() {
    super.add(
      Facility.createFromPlainObject({
        id: uuidv4(),
        name: '',
        tenantId: toJS(this.rootStore.authStore.user.tenantId),
        cantonId: undefined,
        areas: [],
      })
    );
  }

  createArea(facilityId) {
    const facility = this.facilities.find((f) => f.id.toUpperCase() === facilityId.toUpperCase());
    const newArea = Area.createFromPlainObject({id: uuidv4(), name: '', facilityId});
    facility.areas.push(newArea);
  }

  async fetchItemsAsync() {
    const serverResponse = await Api.facility.getAll();
    return serverResponse.data.map((item) => Facility.createFromPlainObject(item));
  }

  async saveItemsAsync(changedItems) {
    await Api.facility.saveAll(changedItems);
  }

  async saveDeletionsAsync(deletedIds) {
    await Api.facility.deleteAll(deletedIds);
  }
}

decorate(FacilityStore, {
  facilities: computed,
});
