/* eslint-disable import/no-cycle */
import { computed, decorate, observable } from 'mobx';
import { Api } from '../middleware/api';
import { User } from '../models/user';

export class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.refreshPromise = null;
  }

  get authenticated() {
    const user = sessionStorage.getItem('user');
    this.user = user ? JSON.parse(user) : null;
    return !!this.user;
  }

  async login(user) {
    const serverResponse = await Api.user.login(user);
    this.setSessionUser(serverResponse.data);
  }

  setSessionUser(data) {
    this.user = User.createFromPlainObject(data);
    sessionStorage.setItem('user', JSON.stringify(this.user));
  }

  async refreshToken() {
    if (this.refreshPromise != null) {
      await this.refreshPromise;
    } else {
      this.refreshPromise = Api.user.refresh();
      await this.refreshPromise;
      this.refreshPromise = null;
    }
  }

  async logout() {
    this.user = null;
    sessionStorage.removeItem('user');
    this.rootStore.clear();
    await Api.user.logout();
  }
}

decorate(AuthStore, {
  authenticated: computed,
  user: observable,
});
