import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';
import {Rate} from './rate';

export class Canton extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.cantonStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      rates: this.rates.map((rate) => Rate.createFromPlainObject(rate).asJson()),
    };
  }
  rates = [];
}

decorate(Canton, {
  id: observable,
  name: observable,
  rates: observable,
});
