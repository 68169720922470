import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';
import {Factor} from './factor';

export class Tenant extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.tenantStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      name: toJS(this.name),
      lastUpdate: toJS(this.lastUpdate),
      users:
        this.users.length > 0
          ? this.users.map((u) => ({
            id: toJS(u.id),
            username: toJS(u.username),
            pw: toJS(u.pw),
            isAdmin: toJS(u.isAdmin),
            isSuperAdmin: toJS(u.isSuperAdmin),
            tenantId: toJS(u.tenantId),
          }))
          : undefined,
      license: this.license ? toJS(this.license).toLowerCase() : undefined,
      timeLimitInMin: toJS(this.timeLimitInMin),
      factors: this.factors ? this.factors.map((factor) => Factor.createFromPlainObject(factor).asJson()) : undefined,
    };
  }

  users = [];
  factors = [];
}

decorate(Tenant, {
  id: observable,
  name: observable,
  lastUpdate: observable,
  users: observable,
  license: observable,
  factors: observable,
  timeLimitInMin: observable,
});
