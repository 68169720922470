import {decorate, observable, toJS} from 'mobx';
import {BaseModel} from './baseModel';

export class Factor extends BaseModel {
  constructor(rootStore) {
    super(rootStore, (root) => root.tenantStore);
  }

  asJson() {
    return {
      id: toJS(this.id),
      startDate: toJS(this.startDate),
      endDate: this.endDate !== null ? toJS(this.endDate) : undefined,
      tenantId: toJS(this.tenantId),
      hourlyWage: toJS(this.hourlyWage),
      comment: toJS(this.comment),
    };
  }
}

decorate(Factor, {
  id: observable,
  startDate: observable,
  endDate: observable,
  hourlyWage: observable,
  tenantId: observable,
  comment: observable,
});
