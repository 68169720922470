import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Result} from 'antd';
import {useHistory} from 'react-router-dom';
import {toJS} from 'mobx';
import Header from '../shared/layouts/Header';
import Button from '../shared/buttons/Button';
import {rootStoreInstance} from '../../stores/StoreContext';

export default class GlobalErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    rootStoreInstance.errorStore.error = error;
  }

  static sendErrorToApi(errorInfo) {
    rootStoreInstance.errorStore.sendError(window.location.toString(), errorInfo);
  }

  componentDidMount() {
    window.addEventListener('error', this.onJSError);
    window.addEventListener('unhandledrejection', this.onUnhandledRejection);
  }

  componentDidCatch(error, errorInfo) {
    GlobalErrorBoundary.sendErrorToApi({jsError: error, reactInfo: errorInfo});
    rootStoreInstance.errorStore.error = error;
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.onJSError);
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
  }

  onUnhandledRejection(ev) {
    GlobalErrorBoundary.sendErrorToApi({promiseRejectionEvent: ev});
    rootStoreInstance.errorStore.error = ev.reason;
  }

  onJSError(event, source, lineno, colno, error) {
    GlobalErrorBoundary.sendErrorToApi({event, source, lineno, colno, jsError: error});
    rootStoreInstance.errorStore.error = error;
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return <GlobalErrorBoundaryContent>{this.props.children}</GlobalErrorBoundaryContent>;
  }
}

const GlobalErrorBoundaryContent = observer((props) => {
  const {t} = useTranslation();
  const history = useHistory();

  const reload = () => {
    if (rootStoreInstance.authStore?.user) {
      window.location.reload();
    } else {
      rootStoreInstance.clear();
      history.push('/');
    }
  };

  const errorValues = Object.values(
    rootStoreInstance.errorStore.error?.error ? toJS(rootStoreInstance.errorStore.error.error) : []
  );

  if (rootStoreInstance?.errorStore?.error) {
    return (
      <>
        <Header />
        {errorValues.includes(504) ? (
          <Result status="error" title={t('errors.messages.biggerProlem')} />
        ) : (
          <Result
            status={500}
            title={t('errors.messages.globalErrorMessage')}
            subTitle={
              process.env.NODE_ENV !== 'production'
                ? t('errors.messages.prolem') + rootStoreInstance.errorStore.error.message
                : null
            }
            extra={(
              <Button type="primary" onClick={() => reload()}>
                {t('confirm.reload')}
              </Button>
            )}
          />
        )}
      </>
    );
  }
  return <>{props.children}</>;
});
