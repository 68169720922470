/* eslint-disable import/no-cycle */
import axios from 'axios';
import {isEmpty} from 'lodash';
import {normalizeResponseError} from '../utils/errors';
import {rootStoreInstance} from '../stores/StoreContext';

const refreshToken = async (error) => {
  const originalRequest = error.config;
  if (error.response.data.message === 'Token has expired') {
    await rootStoreInstance.authStore.refreshToken();
    if (rootStoreInstance?.authStore?.user) {
      const response = await axios(originalRequest);
      return response;
    }
  }
  await rootStoreInstance.authStore.logout();
  return error;
};

const handleErrorResponse = (e) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(e, {response: e && e.response});
  }

  if (!e.response || !e.response.data || isEmpty(e.response.data) || typeof e.response.data === 'string') {
    const status = e.response && e.response.status ? e.response.status : 500;
    // eslint-disable-next-line prefer-promise-reject-errors
    return {error: {status, messages: ['unknown_error']}};
  }

  const {data} = e.response;

  if (data.error || data.errors || Array.isArray(data)) {
    // eslint-disable-next-line no-use-before-define
    data.error = normalizeResponseError(e.response);
    return data;
  }

  return data;
};

export const handleError = (error) =>
  new Promise((resolve, reject) => {
    if (error.response && error.response.status === 401) {
      resolve(refreshToken(error));
    } else {
      reject(handleErrorResponse(error));
    }
  });
