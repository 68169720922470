import {Button} from 'antd';
import React from 'react';
import {withRouter} from 'react-router';

const handleClick = (href, history) => {
  history.push(href);
};

// eslint-disable-next-line object-curly-newline
// eslint-disable-next-line react/jsx-props-no-spreading
const ButtonLink = ({href, children, history, location, match, staticContext, ...rest}) => (
  <Button onClick={() => handleClick(href, history)} {...rest}>{children}</Button>
);

export default withRouter(ButtonLink);
