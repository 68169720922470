import * as moment from 'moment';

export const dateFormat = 'DD.MM.YYYY';
export const timeFormat = 'HH:mm';
export const monthFormat = 'MMMM';
export const monthAndYearFormatForDatePicker = 'YYYY-MM';
export const yearFormat = 'YYYY';
export const dateWithoutYear = 'DD.MM';

export const toTime = (date) => moment(date).format(timeFormat);
export const toDate = (date) => moment(date).format(dateFormat);
export const toOfficialFormatDate = (date) => moment(date).format(moment.HTML5_FMT.DATE);
export const toMonth = (date) => moment(date).format(monthFormat);
export const toYear = (date) => moment(date).format(yearFormat);
export const toDateWithoutYear = (date) => moment(date).format(dateWithoutYear);
export const toMonthWithYearForDatePicker = (date) => moment(date, monthAndYearFormatForDatePicker);
export const toDateForDatePicker = (date) => moment(date, dateFormat);
export const officialDatetoUTC = (date) => moment(date, 'MM/DD/YYYY').utc(true);

export const isDateInBetween = (start, end, date, precision = 'days', includeAll = true) =>
  moment(date).isBetween(start, end, precision, includeAll ? '[]' : '()');
export const isDateBefore = (start, date, precision = 'days') => moment(start).isBefore(date, precision);
export const calculateDuration = (start, end, type) => moment(end).diff(moment(start), type);
export const calculateDifference = (start, end, type) => moment(end).diff(moment(start), type);

export const getRightAfter = (date, type) => moment(date).add(1, type);
export const getRightBefore = (date, type) => moment(date).subtract(1, type);

export const setStartOfMonth = (date) => moment(date).startOf('month').format(moment.HTML5_FMT.DATE);
export const setEndOfMonth = (date) => moment(date).endOf('month').format(moment.HTML5_FMT.DATE);

moment.locale('en');
