/* eslint-disable import/no-cycle */
import {executeGet, executePost, executePut} from '../requests';

const resourcesName = 'v1/benchmarks';

export const BenchmarkApi = {
  getAll: () => executeGet(`${resourcesName}`),
  calculate: (timeSpan) => executeGet(`${resourcesName}/calculate?timeSpan=${timeSpan}`),
  deleteAll: (deletedIds) => executePost(`${resourcesName}/delete`, deletedIds),
  saveAll: (changedItems) => executePut(`${resourcesName}`, changedItems),
};
